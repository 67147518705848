:root {
--foreground-default: #5C7D99;
--foreground-secondary: #A3B9CC;
--foreground-tertiary: #F4FAFF;
--foreground-quaternary: #E5E5E5;
--foreground-light: #FFFFFF;

--background-default: #161616;
--background-secondary: #41474D;
--background-tertiary: #08090A;
--background-light: #41474D;

--primary-default: #5DFDCB;
--primary-dark: #24B286;
--primary-light: #B2FFE7;

--error-default: #EF3E36;
--error-dark: #800600;
--error-light: #FFCECC;


--background-tertiary-shadow: "0 1px 3px 0 rgba(8, 9, 10, 0.5)";
}
 
  html, body { height: 100%; }
  body { 
    background: var(--background-default); 
    margin: 0;
    font-family: var(--font-family-01);
    .app-comp{
      background: var(--background-default);
    } 
  }
 p, span, a, button, input, em, label, div, select{
    font-family: var(--font-family-01);
  }
  
